<template>
  <div class="download">
    <div class="banner">
      <div class="title">考驾照,就用<span>青蛙学车理论速成系统</span></div>
      <div class="content">
        <div class="content-list" v-for="(item, index) in listStr" :key="index">
          <div class="list-icon">
            <img :src="item.img" alt="" />
          </div>
          <div class="list-text">
            <div class="list-top" v-if="index == 0">
              {{ year }}{{ item.title }}
            </div>
            <div class="list-top" v-else>{{ item.title }}</div>
            <div class="list-msg">{{ item.msg }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="software">
      <div class="software-titlt">吊打同行版本下载：</div>
      <div class="software-btns">
        <el-button
          type="primary"
          tag="a"
          href="https://silu-drive-public.oss-cn-guangzhou.aliyuncs.com/download/%E9%9D%92%E8%9B%99%E5%AD%A6%E8%BD%A6%20x32.exe"
          icon="Download"
          >window x32</el-button>
        <el-button
          type="primary"
          tag="a"
          href="https://silu-drive-public.oss-cn-guangzhou.aliyuncs.com/download/%E9%9D%92%E8%9B%99%E5%AD%A6%E8%BD%A6%20x64.exe"
          icon="Download"
          >window x64</el-button
        >
        <!-- <el-button
          type="primary"
          tag="a"
          href=""
          icon="Download"
          >macOS下载</el-button
        > -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const listStr = ref([
  {
    img: "",
    title: "精简题库",
    msg: "新规题库 快速过考",
  },
  {
    img: "",
    title: "最新速成技巧",
    msg: "考试提分利器，临考保高分",
  },
  {
    img: "",
    title: "科一科四模拟考试",
    msg: "多语言模拟，高效备考",
  },
  {
    img: "",
    title: "专项练习",
    msg: "专项模拟，在家也能练",
  },
]);
const date = new Date();
const year = date.getFullYear();
</script>
<style lang="scss" scoped>
.download {
  .banner {
    background-color: #f7fbff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    .title {
      font-size: 26px;
      padding: 20px 0;
      span {
        color: #0091ff;
      }
    }
    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 20px;
      column-gap: 20px;
      .content-list {
        display: flex;
        align-items: center;
        .list-text {
          padding-left: 16px;
        }
      }
    }
  }
  .software {
    padding: 0 20px;
    text-align: center;
    .software-titlt {
      padding: 20px 0;
    }
  }
}
.software-btns > a {
  text-decoration: none;
}
</style>
